<template>
    <OMediaQueryProvider v-slot="{ isTouch }">
        <ActiveFiltersTouch v-if="isTouch" :dataObject="dataObject" :filterObject="filterObject"/>

        <ActiveFiltersPointer v-if="!isTouch" :dataObject="dataObject" :filterObject="filterObject" :hideSavedFilters="hideSavedFilters" :hideHeader="hideHeader"
            :responsiveLayout="responsiveLayout"/>
        <slot name="filterBottom"></slot>
     </OMediaQueryProvider>
</template>

<script setup>
    import { defineProps } from "vue";
    import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';
    import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';

    const ActiveFiltersTouch = useAsyncComponent('o365.vue.components.ActiveFiltersTouch.vue');
    const ActiveFiltersPointer = useAsyncComponent('o365.vue.components.ActiveFiltersPointer.vue');

    const props = defineProps({
        dataObject:Object,
        gridRef:Object,
        hideSavedFilters:{
            type:Boolean,
            default:false
        },
        hideHeader: Boolean,
        responsiveLayout: Boolean
    });

    const filterObject = props.dataObject?.filterObject??props.gridRef.dataObject?.filterObject??props.gridRef.filterObject;
</script>

<style scoped>
    .filter-list-bottom-spacing {
        padding-bottom: 40px;
    }
</style>